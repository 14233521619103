<template>
  <div class="error">
    <div class="error-background__icon">
      <img src="@/assets/images/icon.svg" alt="background">
    </div>
    <div class="card error-card text-center">
      <div class="card-body px-4 py-5 p-sm-5">
        <div class="error-code">404</div>
        <div class="error-title">{{ $t(`__pageNotFound`) }}</div>
        <p>{{ $t(`__pageNotFoundInfo`) }}</p>
        <router-link
          class="btn btn-primary rounded-pill mt-3"
          :to="{ name: 'Dashboard' }"
          >{{ $t("__goBackHome") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style lang="scss">
.error{
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #EEF0F8;
  position: relative;
  // color: $primary;
  &-background__icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: #e3fdfb;
    img{
      width: 1000px;
      position: absolute;
      top: 30%;
      left: 27%;
      transform: translate(-50%, -50%);
      filter: blur(40px);
      opacity: 0.6;
    }
  }
  &-code{
    font-size: 100px;
    font-weight: bold;
    color: $primary;
    font-family: 'Roboto';
  }
  &-title{
    font-size: 46px;
    font-weight: bold;
  }
  &-card{
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: rgba(#fff, 0.6);
    border: none;
  }
}
</style>
